<template>
  <div>
    <ValidationObserver v-slot="context">
      <form @submit.prevent="submit(context)">
        <vx-card class="mb-base">
          <FormLayout>
            <FormLabel>
              <span class="required">ชื่อ</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="ชื่อ" 
                rules="required">
                <vs-input 
                  v-model="formData.title" 
                  class="w-full" 
                  name="seo_name" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>เลือกชนิดการอัปไฟล์</span>
            </FormLabel>
            <FormContent>
              <ul>
                <li>
                  <vs-radio
                    v-model="formData.video_type"
                    vs-name="video_type"
                    vs-value="youtube"
                  >
                    <span>VDO Youtube</span>
                  </vs-radio>
                </li>
                <li>
                  <vs-radio
                    v-model="formData.video_type"
                    vs-name="video_type"
                    vs-value="file"
                  >
                    <span>VDO File</span>
                  </vs-radio>
                </li>
              </ul>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span
              >{{ thumbnailTitle }} <br >
                (.jpg , .png ,.gif)</span>
              <br>
              <span class="font-bold">ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9</span>
              <br>
              <span class="text-warning">ขนาดรูปแนะนำ 1920 x 1080</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                :name="thumbnailTitle"
                rules="lh_mimes:jpg,png,gif|lh_ratio:16,9"
              >
                <ImageUpload
                  :folder="`${baseFolder}/project-vdo/${formData.folder_name}/VDO Thumbnail`"
                  v-model="formData.video_thumbnail"
                  :width="240"
                  :has-delete-button="canDelete(formData.video_thumbnail)"
                  selection-name="video_thumbnail"
                />
                <span 
                  v-show="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout v-show="formData.video_type === 'youtube'">
            <FormLabel>
              <span>URL Youtube (embeded link)</span>
              <br />
              <span class="text-warning text-md">คู่มือวิธีการกรอก url youtube >> </span>
                  <a
                    href="https://assets.lh.co.th/image/upload/v1615266066/website-pro/assets/%E0%B9%83%E0%B8%AA%E0%B9%88_Embed_Code_%E0%B8%97%E0%B8%B5%E0%B9%88_VDO_Project_Clib_l0pvtb.pdf"
                    class="text-md link-manual"
                    target="_blank"
                  >
                    คลิก
                  </a>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="URL Youtube" 
                rules="url">
                <div>
                  <vs-input
                    v-model="formData.video_url"
                    class="w-full"
                    name="asset_value"
                  />
                </div>
                <span 
                  v-show="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout v-show="formData.video_type === 'file'">
            <FormLabel>
              <span>Video (เฉพาะไฟล์ .mp4)</span><br>
              <span class="font-bold">ขนาดไฟล์ vdo ไม่เกิน 300 MB</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="Vdo File"
                rules="lh_size:300_MB"
              >
                <VdoUpload
                  :folder="`${baseFolder}/project-vdo/${formData.folder_name}/VDO`"
                  v-model="formData.video_file"
                  :has-delete-button="canDelete(formData.video_file)"
                  selection-name="video-file"
                />
                <span 
                  v-show="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span class="required">วันที่จะเผยแพร่</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="วันที่จะเผยแพร่"
                rules="required"
              >
                <datepicker
                  :format="useDatepicker.formatDate"
                  :highlighted="{
                    dates: [
                      // Highlight an array of dates
                      formData.published_date
                        ? new Date(formData.published_date)
                        : new Date(),
                    ],
                  }"
                  v-model="formData.published_date"
                  placeholder="Select Date"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span class="required">Description</span>
              <br >
              <span class="text-warning text-sm">กรอกได้ไม่เกิน 300 ตัวอักษร</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="Description"
                rules="required|max:300"
              >
                <vs-textarea
                  v-model="formData.description"
                  class="w-full"
                  name="description"
                  rows="6"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>แสดงบนหน้าเว็บไซต์</span>
            </FormLabel>
            <FormContent>
              <ul>
                <li>
                  <vs-radio
                    v-model="formData.show_homepage"
                    vs-name="show_homepage"
                    vs-value="true"
                  >
                    <span>แสดง</span>
                  </vs-radio>
                </li>
                <li>
                  <vs-radio
                    v-model="formData.show_homepage"
                    vs-name="show_homepage"
                    vs-value="false"
                  >
                    <span>ไม่แสดง</span>
                  </vs-radio>
                </li>
              </ul>
            </FormContent>
          </FormLayout>
        </vx-card>
        <vx-card class="mb-base">
          <FormLayout>
            <FormLabel>
              <span>แก้ไขล่าสุดโดย</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <span class="mt-2"> {{formData.updated_by}}</span>
            </div>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>แก้ไขล่าสุดวันที่</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <span class="mt-2"> {{formatDate(formData.updated_at)}}</span>
            </div>
          </FormLayout>
          <FormLayout>
            <FormLabel />
            <FormContent class="flex space-x-4">
              <vs-button 
                @click="submit(context)"
              >
                {{ formData.id ? 'Update' : 'Submit' }}
              </vs-button>
              <vs-button 
                v-if="formData.id !== null" 
                @click="preview()"
              >Preview</vs-button>
            </FormContent>
          </FormLayout>
        </vx-card>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import useProjectVdoUtil from '@/use/useProjectVdoUtil'
import useCrud from '@/use/useCrud'
import useNotify from '@/use/useNotify'
import Wyswyg from '@/components/Wyswyg'
import ImageUpload from '@/components/upload/ImageUpload'
import VdoUpload from '@/components/upload/VdoUpload'
import env from '@/env'
import useDatepicker from '@/use/useDatepicker'
import Datepicker from 'vuejs-datepicker'
import get from 'lodash/get'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'

export default {
  name: 'ProjectVdoForm',
  components: {
    ValidationObserver,
    Wyswyg,
    ImageUpload,
    VdoUpload,
    Datepicker
  },
  setup(props, ctx) {
    const { success: notifySuccess, error: notifyError, ...notifyFn } = useNotify(ctx)
    const {
      root: { $store, $router, $vs },
    } = ctx
    const {
      formData,
      fetchByRouteId,
    } = useProjectVdoUtil(ctx)
    const baseFolder = ref(env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER)
    const crudForm = useCrud(ctx, 'projectVdo')
    crudForm.routePrefix.value = 'vdo-project'

    onMounted(() => {
      fetchByRouteId()
    })

    const updateItem = ({ validate }) => {
      validate().then((isValid) => {
        if (!isValid) return notifyError({ text: 'กรุณาตรวจสอบข้อมูลให้ถูกต้อง' })
        $vs.loading()

        const activeUser = ctx.root.$store.state.AppActiveUser
        const email = get(activeUser, 'email', '')
        let created_by = formData.created_by
        const updated_by = email

        // add user created by
        if (!created_by) {
          created_by = email
        }

        $store
          .dispatch('projectVdo/updateItem', {
            formData: {
              ...omit(formData, ['updated_at', 'created_at']),
              created_by,
              updated_by
            }
          })
          .then(() => {
            $router.push({ name: 'vdo-project' })
            $vs.loading.close()
            notifySuccess({ text: `${formData.uuid ? 'แก้ไข' : 'เพิ่ม'}ข้อมูลเรียบร้อย` })
          })
          .catch((error) => {
            $vs.loading.close()
            console.log('error: ', error)
            console.log('error', JSON.stringify(error))
            notifyError({ text: JSON.stringify(error) })
          })
      })
    }

    const submit = (ctx) => {
      const existingDb = $store.state.footer.items.find(v => v.url === formData.url)
      if (existingDb && existingDb.id !== formData.id) { 
        return notifyError({
          text: 'ข้อมูลมีอยู่ในระบบ ไม่สามารถเพิ่มข้อมูลได้'
        })
      }

      if (formData.uuid) {
        notifyFn.confirm({
          callback: () => updateItem(ctx) 
        })
      } else {
        crudForm.addItem(ctx, {
          ...formData,
        })
      }
    }

    const preview = () => {
      const lang = $store.state.locale.currentLang
      window.open(`${env.VUE_APP_WEBSITE_URL}/${lang}/VDO-Review`)
    }


    const canDelete = (files) => {
      return !isEmpty(files)
    }

    const thumbnailTitle = computed(() => {
      return formData.video_type === 'file' ? 'Thumbnail VDO' : 'Thumbnail Youtube' 
    })

    watch(
      () => formData.url,
      (newValue) => {
        if (newValue) {
          const decodeUrl = decodeURIComponent(newValue)
          if (decodeUrl !== formData.url) {
            formData.url = decodeUrl
          }
        }
      }
    )

    return {
      ...crudForm,
      baseFolder,
      updateItem,
      formData,
      submit,
      canDelete,
      thumbnailTitle,
      useDatepicker: useDatepicker(),
      preview
    }
  },
}
</script>

<style lang="scss" scoped>
.link-manual {
  color: red !important;
}
.required:after {
    content: ' *';
    color: red;
  }
</style>
